<div class="container">
  <div>
    <app-header>

    </app-header>



  </div>
  <div class="searchbarContainer">


    <atomic-search-interface style="justify-content: center;" #searchInterface
      fields-to-include='["vvmctherapeuticarea", "sourcename","contentdocumenttype","contentdocumentsubtype","qddocumenttype","qddocumentsubtype","gmsavvmcapprovaldate"]'>
      <!-- <atomic-search-layout> -->
      <!-- <div class="header-bg"></div>
      <atomic-layout-section section="search">
        <atomic-search-box></atomic-search-box>
      </atomic-layout-section> -->
      <!-- <atomic-layout-section id="searchbox" section="search"> -->
      <div style="display: flex;
        flex-direction: column; justify-content: center; align-items: center;">
        <span class="heading">V&I Search Navigator</span>
        <span class="subHeading">Unlocking Knowledge, One Search at a Time</span>
        <atomic-search-box redirection-url="/" id="searchBar" class="searchBox"></atomic-search-box>
      </div>

      <!-- </atomic-layout-section> -->
      <!-- <atomic-layout-section section="facets">
        <atomic-facet-manager>
           <atomic-category-facet
            field="geographicalhierarchy"
            label="World Atlas"
            with-search
          >
          </atomic-category-facet> -->
      <!-- <atomic-facet field="vvmctherapeuticarea" label="T.A."></atomic-facet>
          <atomic-facet field="source" label="Source" display-values-as="link"></atomic-facet> -->
      <!-- <atomic-facet
            field="year"
            label="Year"
            display-values-as="box"
          ></atomic-facet> -->
      <!-- <atomic-numeric-facet
            field="ytviewcount"
            label="Youtube Views"
            depends-on-filetype="YouTubeVideo"
            with-input="integer"
          ></atomic-numeric-facet> -->
      <!-- <atomic-numeric-facet
            field="ytlikecount"
            label="Youtube Likes"
            depends-on-filetype="YouTubeVideo"
            display-values-as="link"
          > -->
      <!-- <atomic-numeric-range
              start="0"
              end="1000"
              label="Unpopular"
            ></atomic-numeric-range> -->
      <!-- <atomic-numeric-range
              start="1000"
              end="8000"
              label="Well liked"
            ></atomic-numeric-range>
            <atomic-numeric-range
              start="8000"
              end="100000"
              label="Popular"
            ></atomic-numeric-range>
            <atomic-numeric-range
              start="100000"
              end="999999999"
              label="Treasured"
            ></atomic-numeric-range> 
          </atomic-numeric-facet>-->
      <!-- <atomic-numeric-facet field="sncost" label="Cost Range (auto)">
            <atomic-format-currency currency="CAD"></atomic-format-currency>
          </atomic-numeric-facet>
          <atomic-timeframe-facet label="Timeframe" with-date-picker>
            <atomic-timeframe unit="hour"></atomic-timeframe>
            <atomic-timeframe unit="day"></atomic-timeframe>
            <atomic-timeframe unit="week"></atomic-timeframe>
            <atomic-timeframe unit="month"></atomic-timeframe>
            <atomic-timeframe unit="quarter"></atomic-timeframe>
            <atomic-timeframe unit="year"></atomic-timeframe>
            <atomic-timeframe
              unit="year"
              amount="10"
              period="next"
            ></atomic-timeframe>
          </atomic-timeframe-facet>
          <atomic-rating-facet
            field="snrating"
            label="Rating"
            number-of-intervals="5"
          >
          </atomic-rating-facet>
          <atomic-rating-range-facet
            facet-id="snrating_range"
            field="snrating"
            label="Rating Range (auto)"
            number-of-intervals="5"
          >
          </atomic-rating-range-facet>
          <atomic-color-facet
            field="filetype"
            label="Files"
            number-of-values="6"
            sort-criteria="occurrences"
          ></atomic-color-facet> -->
      <!-- </atomic-facet-manager> -->
      <!-- </atomic-layout-section> -->
      <!-- </atomic-search-layout> -->
    </atomic-search-interface>



  </div>




  <div class="content">
    <main>

      <div *ngIf="false" style="display: flex; justify-content: center;margin-bottom: 10px;">

        <div class="border-box">
          <div class="textContainer">
            <span>
              <div [innerHTML]="outage1"></div>
              <div [innerHTML]="outage2"></div>
            </span>
          </div>
        </div>
      </div> 



      <div style="display: flex; justify-content: center;">
        <div class="border-box">
          <div class="textContainer">
            <span>
              <div>External use of materials by Field Medical is governed by applicable SOPs and requires use of, and
                recording in, the designated system of record.</div>
              <div>Availability of content through this Navigator does not relieve users of compliance obligations.
              </div>
            </span>
          </div>
        </div>
      </div>

      <div class="row">
        <app-recomendation-section></app-recomendation-section>
      </div>

      <div class="containerMain">
        <!-- <app-recomendation-section></app-recomendation-section> -->
        <!-- <app-data-source-section></app-data-source-section> -->
        <div class="loadMore">
          <a style="cursor: pointer;" target="_blank" (click)="customEventsMore()"
            href="https://collaboration.merck.com/sites/GlobalMedicalAffairs/SitePages/GMSA-Search-Hub-Resource-Center.aspx?from=SendByEmail&e=TqbH_-oBVEWtqWDQwyvz3g&at=9GMAC%20System%20Support%20Form%20link:%20https://servicerequests.merck.com/dwp/app/#/profile?serviceId=7401">
            <button style="cursor: pointer;" class="btnlm">
              <span style="cursor: pointer;" class="btnText">
              </span> Learn more about Search Navigator
            </button>
          </a>
        </div>
      </div>

      <div class="containerMain" style="margin-bottom:3rem">
        <div class="">
          <div class="title">
            <span>Additional Key Links
            </span>
          </div>
          <span class="subText">Note: Access required for each link below</span>
          <div class="card-container">
            <ul style="max-width:62%;margin-top:2rem;line-height:1.7">
              <li>

               <span class="spanText"> <a (click)="customEventsAxonify()" style="color: teal;" target="_blank" href = "https://learning.axonify.com/">Axonify</a>: A dynamic repository of on-demand Global Scientific Training (GST) resources.
               </span>
              </li>
              <li>
                <span class="spanText">
                  <a style="color:teal" (click)="customEventsGlobal()" target="_blank" href="https://gsccongressportal.merck.com/">Global Scientific Content Congress Portal</a>: Portal that houses posters and oral presentations from scientific congresses, intended for use by Medical Affairs Scientific Personnel.
                </span>
              
              </li>

              <li>
                <span class="spanText">
                  <a style="color:teal" (click)="customEventsVISearch()" target="_blank" href="https://collaboration.merck.com/sites/GlobalMedicalAffairs/SitePages/GMSA-Search-Hub-Resource-Center.aspx?">V&I Search Navigator Resource Center</a>:  Home page of the Search Navigator with overviews, links, help articles and more.
                  
                </span>
              
              </li>
              <li>
                <span class="spanText">
                  <a style="color:teal" (click)="customEventsModel()" target="_blank" href="https://modelrepository.merck.com/#/">Model Repository</a>: Central place to document, access, share, and execute health economic models.
                  
                </span>
              
              </li>
              <li>
                <span class="spanText">
                  <a style="color:teal" (click)="customEventsGlobalAccessNetwork()" target="_blank" href="https://collaboration.merck.com/sites/gan/Pages/GANHome.aspx">Global Access Network</a>: GMAx resource
                 
                </span>
              
              </li>
              <li>
                <span class="spanText">
                  <a style="color:teal" (click)="customEventsScited()" target="_blank" href="https://collaboration.merck.com/sites/scited">SCITED</a>: repository of company-authored publications brought to you by Scientific Communications and Information Sciences (SCIS)
                 
                </span>
              
              </li>
              <li>
                <span class="spanText">
                  <a style="color:teal" (click)="customEventsRWD()" target="_blank" href="https://collaboration.merck.com/sites/RWDEx-subsite/Pages/index.aspx">RWDEx</a>: next generation cloud-based analytics platform for RWD and value evidence
                 
                </span>
              
              </li>
              <li>
                <span class="spanText">
                  <a style="color:teal" (click)="customEventsCore()" target="_blank" href="https://collaboration.merck.com/sites/core_community/SitePages/Core_Strategic_Partnerships.aspx">CORE Strategic Partnerships</a>: Overview of RWE global network of partners
                </span>
              
              </li>
            </ul>
            <!-- <div *ngFor="let item of dataSources;" class="card">
                  <div class="card-content">
                      <h2>{{item.title}}</h2>
                      <p>{{item.subtitle}}</p>
                  </div>
              </div> -->

          </div>
        </div>

        <!-- <div class="loadMore" style="margin-top:2rem">
          <a style="cursor: pointer;" target="_blank"
          (click)="customEventsGMSASource()"
            href="https://collaboration.merck.com/sites/GlobalMedicalAffairs/SitePages/GMA-OneMedicalGateway.aspx">
            <button style="cursor: pointer;" class="btnlm">
              <span style="cursor: pointer;" class="btnText">
              </span> Learn more about GMSA sources
            </button>
          </a>
        </div> -->
      </div>


    </main>
  </div>


  <app-footer></app-footer>
</div>