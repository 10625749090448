  <!-- <div class="header">
    <div class="header-box header-box1"><a href="/landing"><img class="logo-img" src="./../../assets/merck-assets/MerckIconWhite.png"/> <span class="logo-label">Search Navigator</span></a> </div>
    <div class="header-box header-box2" style="display: flex; display: -webkit-flex; align-items: center;">
      <div style="flex-basis: 95%;"><atomic-search-box clear-button="true" data-enable-atomic="true" enable-query-syntax="false" clear-filters="false" (change)="getSearchTerm($event.target.value)"  style="margin: 0.4rem;height: 2.4rem;"></atomic-search-box></div>
      <div>
        <button  title="Clear Search Criteria and filters"style="border-radius: 5px; 
        background-color: teal;
        color: #ffffff;
        cursor: pointer;
        border: 1px solid #ffffff; border-radius: 8px;" type="button" (click)="clearAllSearch();customEventClearAll()"><span class="material-icons outlined" style="padding: 5px;"> search_off</span></button>
      </div>
    </div>
    <div class="header-box header-box3">
      <app-csn-popup *ngIf="showPopup" [title]="title" [body]="body" [feature]="feature" (close)="closePopup()"></app-csn-popup>
      <div>
        <div (click)="startManualSync();customEventManualSync()" class="header-label progress-color" [style.width]="widthOfProgress+'%'" style="display: flex; display: flex;  justify-content: space-between; align-items: center; cursor: pointer;">
          <span  [id]="rotate" class="material-icons outlined" style="color: white;">autorenew</span>
          <span class="sync-label" style="opacity: 1;">Sync to OneDrive</span>
        </div>
      </div>

      <div (click)="loadOneDriveDocs();customEventsLoadOneDrive()" class="header-label" style="display: flex; display: flex; justify-content: space-between; align-items: center;">
        <span class="material-icons outlined sync-icon">cloud_queue</span>
        <span class="sync-label">My OneDrive</span></div>
      
      <div (click)="loadAllCollections();customEventsLoadAllCollection()" class="header-label" style="display: flex; display: flex; justify-content: space-between; align-items: center;">
        <span class="material-icons sync-icon">bookmark_border</span>
        <span class="sync-label">Collections</span>
     <div *ngIf="loadCollectionsPartial" style="color: black;">
        <app-collections (mycollections)="myCollectionClicked($event)" [loadCollectionsPartial]="loadCollectionsPartial"></app-collections></div>
      </div>
        <div (click)="showSearches();customEventsShowSarches()" class="header-label">
          <span class="material-icons outlined sync-icon">star_outline</span>
          <span class="sync-label" style="flex-shrink: 0;">Saved Searches</span>
      <span *ngIf="loadSavedSearchPartial" class="collection-dropdown" style="color: black;">
        <app-savedsearch [samllPopup]="loadSavedSearchPartial"></app-savedsearch></span>
      </div>
      <div (click)="openNotifications();customEventsOpenNotifications()" class="header-label" style="display: flex;
      align-items: center; cursor: pointer;">
      <span class="material-icons outlined" style="color: white;">notifications</span>
        <span *ngIf="showNotificationCount"   class="count">{{notificationsCount}}</span></div>
     <div class="env-name">Dev</div>   
      
      <div class="header-label "><div class="round-div">
        <span class="shortname">{{nickName}}</span></div>
      <div class="fullname">{{fullName}}</div>
    </div>
    </div>
    
  </div> -->





  <!-- <div class="new-header" style="line-height: 50px;">
    <div style="display: inline-block; width: 25%; height: 50px;">
      <div style="display: inline-block; width: 25%; height: 50px;"><img class="logo-img" style="vertical-align:top" src="./../../assets/merck-assets/MerckIconWhite.png"/></div>
      <div style="display: inline-block; width: 75%; height: 50px;">
        <span class="logo-label">Search Navigator</span>
      </div>
    </div>
    <div style="display: inline-block; width: 25%;">
      <div style="display: inline-block; width: 80%;">
        <atomic-search-box clear-button="true" data-enable-atomic="true" enable-query-syntax="false" clear-filters="false" (change)="getSearchTerm($event.target.value)"  style="margin: 0.4rem;height: 2.4rem;"></atomic-search-box>
      </div>
      <div style="display: inline-block; width: 20%; vertical-align: -webkit-baseline-middle;">
        <button  title="Clear Search Criteria and filters"style="border-radius: 5px; 
        background-color: teal;
        color: #ffffff;
        cursor: pointer;
        border: 1px solid #ffffff; border-radius: 8px;" type="button" (click)="clearAllSearch();customEventClearAll()"><span class="material-icons outlined" style="padding: 5px;"> search_off</span></button>
      </div>
    </div>


    <div class="action-btns" style="display: inline-block; width: 50%;">


      <app-csn-popup *ngIf="showPopup" [title]="title" [body]="body" [feature]="feature" (close)="closePopup()"></app-csn-popup>
      <div style="display: inline-block;">
        <div (click)="startManualSync();customEventManualSync()" class="progress-color" [style.width]="widthOfProgress+'%'">
          <span style="display: inline-block;"  [id]="rotate" class="material-icons outlined" style="color: white;display: inline;vertical-align: middle;">autorenew</span>
          <span style="display: inline-block;" class="sync-label" style="opacity: 1;">Sync to OneDrive</span>
        </div>
      </div>


      <div (click)="loadOneDriveDocs();customEventsLoadOneDrive()"  style="display: inline-block;">
        <span class="material-icons outlined sync-icon">cloud_queue</span>
        <span class="sync-label">My OneDrive</span></div>




      <div (click)="loadAllCollections();customEventsLoadAllCollection()" style="display: inline-block;">
        <span class="material-icons sync-icon" style="vertical-align: text-bottom;">bookmark_border</span>
        <span class="sync-label">Collections</span>
     <div *ngIf="loadCollectionsPartial" style="color: black;">
        <app-collections (mycollections)="myCollectionClicked($event)" [loadCollectionsPartial]="loadCollectionsPartial"></app-collections></div>
      </div>




      <div (click)="showSearches();customEventsShowSarches()" style="display: inline-block;">
        <span class="material-icons outlined sync-icon">star_outline</span>
        <span class="sync-label">Saved Searches</span>
    <span *ngIf="loadSavedSearchPartial" class="collection-dropdown" style="color: black;">
      <app-savedsearch [samllPopup]="loadSavedSearchPartial"></app-savedsearch></span>
    </div>



    <div (click)="openNotifications();customEventsOpenNotifications()" style="display: inline-block;">
    <span class="material-icons outlined" style="color: white;">notifications</span>
      <span *ngIf="showNotificationCount">{{notificationsCount}}</span></div>


   <div style="display: inline-block;" class="env-name">Dev</div> 
   
    
    <div style="display: inline-block; vertical-align: super;"><div class="round-div" >
      <span class="shortname">{{nickName}}</span></div>
    <div class="fullname">{{fullName}}</div>
  </div>




    
    </div>
  </div> -->















  <div class="new-header" style="line-height: 50px;">
    <div class="new-header-block" style="display: grid;">

      <div *ngIf="isRockyMountainUser == false" style="cursor: pointer;" (click)="redirectTo('/landing')">
        <div style="display: inline-block;height: 50px;line-height: 45px;"><img class="logo-img" style="vertical-align:top" src="./../../assets/merck-assets/MerckIconWhite.png"/></div>
        <div style="display: inline-block;height: 50px;vertical-align: middle;line-height: 45px;">
          <span class="logo-label">V&I Search Navigator</span>
        </div>
      </div>

      <div  *ngIf="isRockyMountainUser == true"  style="cursor: pointer;">
        <div style="display: inline-block;height: 50px;line-height: 45px;"><img class="logo-img" style="vertical-align:top" src="./../../assets/merck-assets/MerckIconWhite.png"/></div>
        <div style="display: inline-block;height: 50px;vertical-align: middle;line-height: 45px;">
          <span class="logo-label">V&I Search Navigator</span>
        </div>
      </div>


      <div style="line-height: 49px;padding-right: 1rem;">
        <div style="display: inline-block; width: 80%; margin-left: -1rem;margin-right: 0.5rem;">
          <atomic-search-box clear-button="true" data-enable-atomic="true" enable-query-syntax="false" clear-filters="false" (change)="getSearchTerm($event.target.value)"  style="margin: 0.4rem;height: 2.4rem;">
          
             <atomic-search-box-query-suggestions></atomic-search-box-query-suggestions>
             <atomic-search-box-recent-queries></atomic-search-box-recent-queries>

          </atomic-search-box>
        </div>
        <div class="clearBtn">
          <button  title="Clear Search Criteria and filters"style="border-radius: 5px; 
          background-color: teal;
          color: #ffffff;
          cursor: pointer;
          border: 1px solid #ffffff; border-radius: 8px;" type="button" (click)="clearAllSearch();customEventClearAll()"><span class="material-icons outlined" style="padding: 5px;"> search_off</span></button>
        </div>
      </div>

      <div>
        <div class="action-new-btns">
          <div>
            <app-csn-popup *ngIf="showPopup" [title]="title" [body]="body" [feature]="feature" (close)="closePopup()"></app-csn-popup>
            <div style="display: inline-block;">

              <div style="font-size: 1vw;">
                <div *ngIf="isRockyMountainUser == false" (click)="startManualSync();customEventManualSync()" class="progress-color" [style.width]="widthOfProgress+'%'">
                  <span style="display: inline-block;"  [id]="rotate" class="material-icons outlined sync-btn-icon" style="color: white;display: inline;vertical-align: middle; position: absolute;">autorenew</span>
                  <span style="display: inline-block;" class="sync-label" style="opacity: 1;">Sync to OneDrive</span>
                </div>
              </div>

            </div>
      
          </div>
          <div>
            <div *ngIf="isRockyMountainUser == false" (click)="loadOneDriveDocs();customEventsLoadOneDrive()"  style="display: inline-block;font-size: 1vw;vertical-align: middle; cursor: pointer;">
              <span class="material-icons outlined sync-icon" style="">cloud_queue</span>
              <span class="sync-label">My OneDrive</span></div>
          </div>
          <div>
            <div *ngIf="isRockyMountainUser == false" (click)="loadAllCollections();customEventsLoadAllCollection()" style="display: inline-block;font-size: 1vw;vertical-align: middle; cursor: pointer;">
              <span class="material-icons sync-icon" style="">bookmark_border</span>
              <span class="sync-label">Collections</span>
           <div *ngIf="loadCollectionsPartial" style="color: black;">
              <app-collections (mycollections)="myCollectionClicked($event)" [loadCollectionsPartial]="loadCollectionsPartial"></app-collections></div>
            </div>
          </div>
          <div>
            <div *ngIf="isRockyMountainUser == false" (click)="showSearches();customEventsShowSarches()" style="display: inline-block;font-size: 1vw;vertical-align: middle; cursor: pointer;">
              <span class="material-icons outlined sync-icon" style="">star_outline</span>
              <span class="sync-label">Saved Searches</span>
          <span *ngIf="loadSavedSearchPartial" class="collection-dropdown" style="color: black;">
            <app-savedsearch [samllPopup]="loadSavedSearchPartial"></app-savedsearch></span>
          </div>
          </div>
          <div>
            <div *ngIf="isRockyMountainUser == false" (click)="openNotifications();customEventsOpenNotifications()" style="display: inline-block;font-size: 1vw;vertical-align: middle; cursor: pointer;">
              <span class="material-icons outlined" style="color: white;vertical-align: middle;">notifications</span>
                <span *ngIf="showNotificationCount && notificationsCount > 0" style="    top: -6px;
                position: relative;
                background: red;
                border-radius: 50%;
                padding: 4px;
                left: -8px;
                font-family: invention;
                font-weight: 700;">{{notificationsCount}}</span></div>
          </div>
          <div>
            <div style="display: inline-block;font-family: invention;" class="env-name"></div>

          </div>
          <div
          [routerLink]="!isRockyMountainUser ? '/settings' : null"
          style="line-height: 50px; cursor: pointer;"
          [ngStyle]="{ pointerEvents: isRockyMountainUser ? 'none' : 'auto', opacity: isRockyMountainUser ? 0.5 : 1 }"
        >
          <div style="display: inline-block; vertical-align: middle;">
            <div class="round-div">
              <span class="shortname" style="margin: 0;">{{ nickName }}</span>
            </div>
            <div class="fullname">{{ fullName }}</div>
          </div>
        </div>        
        </div>
      </div>

    </div>
  </div>


















  

   <!-- <div *ngIf="export" >
                <app-export-to-excel [exportToExcelPopUp]="exportPopUp" (close)="closeExportPopup()"></app-export-to-excel>
   </div> -->


  <div *ngIf="notificationToggle"  class="overlay"  id="overlay"  (click)="notificationToggle=false"></div>
  <div *ngIf="notificationToggle"  >
  
      <!-- The content of the pop-up -->
  
      <div
  class="popup-content">
  
       <!-- <button style="position: absolute;  right:565px" (click)="closeNotifications()">Close</button> -->
            <div style="display: flex;justify-content: space-between; border-bottom: 1px solid #00857c; padding: 7px"><span style=" display: inline; font-size:16px; font-weight: 700; margin-left: 4px;">Notifications</span><span *ngIf="notifications.length != 0" (click)="clearAll();customEventsClearAll()" style=" margin-left:393px; margin-right: 16px; font-weight: 400 ; font-size: 1.0rem;cursor: pointer;color:teal;text-decoration: underline;">Clear All</span></div>
            <!-- <hr color="teal"> -->
            <div *ngIf="notifications.length > 0">
          <ul style="list-style-type: none;margin-left: -29px;">
            <!-- <li style="font-weight: bold; display: flex; justify-content: space-between;"> <a (click)="closeMainTab()" style="color:teal; font-weight: 400; margin-left: 160px; text-decoration: none;">Close</a></li> <br> -->
                   <li style="font-weight: bold; display: flex; justify-content: space-between;">New/Updated content in your Saved Search</li> <br>
                   <li style="line-height: 2;" *ngFor="let notification of notifications"> 
                    <div id="remove_{{notification.ID}}" style="display: flex;justify-content: space-between;align-items: center;">
                      <span>
                       <span  style="color:teal; font-weight: 700; overflow: auto; font-size: 14px; ">{{notification.PATTERN_NAME}}</span> 
                       <span (click)="expandNotificationRecord(notification);customEventsExpandNotifications(notification.PATTERN_NAME)" class="material-icons outlined" style="position: relative;
                       top: 7px;
                       cursor: pointer;
                       color: #373737;
                       width: 10.91px;
                       height: 6px;
                       margin-left: 3px;">{{notification.collapse}}</span>
                      </span>
                       <span (click)="removeSavedSearchFromNotifications(notification.ID);customEventsNotificationRemove(notification.PATTERN_NAME)"  style=" margin-left:10px; margin-right: 25px; font-size: 0.8rem;cursor: pointer;color:teal;font-weight: 400; font-size: 14px;">Clear</span>
                    </div>
                   <div *ngIf="notification.collapse == 'expand_less'">
                      <hr style="color:#C6C6C6; bottom: 1px;">
                           <ul style="list-style-type: none; margin-right: 40px;"  id={{notification.ID}}>
                                 <!-- <li style="display: flex;justify-content: space-between;align-items: center; padding:5px,12px,5px,12px; font-size:14px; line-height:22px; font-weight:700;" *ngFor = "let update of updates">
                                  <span (click)="showNotificationRelatedResults(update.UPDATED_DOCS_NUMBER)" [style.color]="update.VISITED == 1 ? 'grey':'teal'" style="  font-weight: 400; overflow: auto; text-decoration: underline; cursor: pointer;">{{update.UPDATED_DOCS}}</span> <span (click)="removeNotification(update.ID)"  style=" margin-left:10px; font-weight: 400; font-size: 1.0rem;cursor: pointer;color:teal;">X</span>
                                 </li>
                                 <span *ngIf="loader">Loading...</span> -->
                           </ul>
                           <span *ngIf="loader" class='loader'>Loading...</span>
                           
                           
                   </div>

                  
                   </li>
                   <span *ngIf="loadMore" (click)="loadMoreNotifications()" style="color: teal;
                   position: relative;
                   cursor: pointer;
                   margin-bottom: 43px;
                   border-top: 1px solid #c6c6c6;
                   padding: 7px;
                   font-weight: 400;
                   text-decoration: underline;
                   top: 52px;
                   display: flex;">Load more...</span>
          </ul>

          </div>

           <div     style=" margin-top: 11px;
           margin-left: 12px; font-family: invention; font-size:14px" *ngIf="notifications.length == 0">
             <span>No notifications are available</span>
           </div>
        <!-- If the content exceeds the container height, a scroll bar will appear -->
      </div>
    </div>
  
  
    <div *ngIf="clearSavedSearch"
    class="overlay"
    id="overlay">
  
    
    <div
    class="popup-content_clear_savedsearch">
    
         <!-- <button style="position: absolute;  right:565px" (click)="closeNotifications()">Close</button> -->
    
            <ul style="list-style-type: none;">
                     <li *ngIf="!isPatternDeleted">Do you want to clear notifications for <span style="font-weight: bold;">"{{patternName}}"</span>  ?</li> <br>
                     <li *ngIf="isPatternDeleted">{{patternName}}</li> <br>
                    <li *ngIf="!clearSavedSearchOperation"><button (click)="closeNotificationClearTab(0)"  class="docFavCancleBtn">No</button> <button  (click)="onConfirmationRemoveSavedSearch()" class="docFavSaveBtn">Yes</button></li>
                    <li *ngIf="clearSavedSearchOperation"><button (click)="closeNotificationClearTab(1)" class="docFavSaveBtn">Close</button> </li>
            </ul>
          <!-- If the content exceeds the container height, a scroll bar will appear -->
        </div>
    </div>
  
    
    <div *ngIf="clearAllNotifications"
    class="overlay"
    id="overlay">
  
  
    <!-- <div
    class="popup-content_clear_all">
    
    <div class="title">Notifications</div>
    
            <ul style="list-style-type: none;">
                     <li> {{clearAllNotificationsText}}</li> <br>
                    <li *ngIf="clearAllNotificationsFlag"><button (click)="closeClearAllTab()" class="docFavSaveBtn">No</button> <button  (click)="onConfirmationClearAllNotifications()" class="docFavCancleBtn">Yes</button></li>
                    <li  *ngIf="!clearAllNotificationsFlag"><button (click)="closeClearAllTab()" class="docFavSaveBtn">Close</button> </li>
            </ul>
          
        </div> -->
    </div>




<div *ngIf="clearAllNotifications">
      <div id="popup1" class="overlay" (click)="clearAllNotifications=false"></div>
      <div class="popup">
          <!-- <a class="close" href="javascript:void(0);" (click)="SavedSearchSubscribePopupShow = false;flag = ''">&times;</a> -->
          <div class="content">
              <div class="title">Notifications</div>
              <div style="display: flex; flex-direction: column;">
                  <div class="input-section">
                    {{clearAllNotificationsText}}
                  </div>
                  <div class="btn-section">
                       <button *ngIf="clearAllNotificationsFlag" (click)="closeClearAllTab()" class="docFavCancleBtn" >No</button> <button *ngIf="clearAllNotificationsFlag" (click)="onConfirmationClearAllNotifications()" class="docFavSaveBtn">Yes</button>

                      <div  *ngIf="!clearAllNotificationsFlag"><button (click)="closeClearAllTab()" class="docFavSaveBtn">Close</button> </div>
                  </div>
              </div>
          </div>
      </div>
  </div>


   <!-- <div *ngIf="clearAllNotifications">
    <div id="popup1" class="overlay" (click)="clearAllNotifications = false"></div>
    <div class="popup">
       
        <div class="content">
            <div class="title">Notifications</div>
            <div style="display: flex; flex-direction: column;">
                <div class="input-section">
                    Do you want to clear all notifications?
                </div>
                <div class="btn-section">
                    <button style="color:teal !important"  class="docFavCancleBtn" (click)="closeClearAllTab(0)" >No</button>
                    <button (click)="onConfirmationClearAllNotifications()" class="docFavSaveBtn" >Yes</button>
                </div>
                <div class="btn-section">
                  <button style="color:teal !important"  class="docFavCancleBtn" (click)="closeClearAllTab(1)" >Close</button>
                  
              </div>
            </div>
        </div>
    </div>
</div> -->
  
