<header class="header">

  
  <div class="logo" (click)="gotoLanding()">
    <img src="../../assets/images/logo.png" alt="Logo">
    <span>V&I Search Navigator</span>
  </div>
  <nav>
    <ul style="align-items: center;">
      <li>
        <div class="syncImage">
          <!-- <img class="syncImageContainer" src="../../assets/images/sync.png" alt="sync"> -->
          <!-- <span>
            Sync to OneDrive
          </span> -->
        </div>
      </li>
      <li *ngIf="showHeader" style="cursor: pointer;" (click)="showDrive()"><span class="material-icons outlined sync-icon">cloud_queue</span><span class="sync-label">My OneDrive</span></li>
      <li *ngIf="showHeader" style="cursor: pointer;" (click)="showCollections()"><span class="material-icons outlined sync-icon">bookmark_border</span><span class="sync-label">Collections</span></li>
      <li *ngIf="showHeader" style="cursor: pointer;" (click)="showSavedSearches()"><span class="material-icons outlined sync-icon">star_outline</span><span class="sync-label">Saved Searches</span></li>
      <!-- <li *ngIf="isAdmin" id="adminId"  class="admin-link" (click)="navigateToUserDeprovision()">Admin</li> -->
      <li style="cursor: pointer;" id="adminId" *ngIf="!userDeprovPage && isAdmin && showHeader"  class="admin-link" (click)="navigateToUserDeprovision()">Admin</li>
<!--       <li>My OneDrive</li>
      <li>Collections</li>
      <li>Saved Searches</li> -->
      

      <li *ngIf="showHeader" style="cursor: pointer;">
        <div class="notification" (click)="showNotifications()">
          <span>
            <!-- <img class="notifyImage" src="../../assets/images/icon.png" alt="notification"> -->
            <span class="material-icons outlined" style="color: white;vertical-align: middle;">notifications</span>
          </span>
          <!-- <span class="count">6</span> -->
          <!-- <span (click)="openNotifications()" class="count">{{notificationsCount}}</span> -->
          <span *ngIf="showNotificationCount" (click)="showNotifications()" class="count">{{notificationsCount}}</span>
        </div>
      </li>
      <li>
        <div class="env-div">
           

        </div>
      </li>
      <li style="cursor: pointer;">
        <div routerLink="/settings" class="circle">
          <span class="text">{{nickName}}</span>
          <div class="fullname">{{fullName}}</div>
        </div>


        <!-- <div class="header-label " (click)="goToSettings()">
          <div class="round-div">
           <span class="shortname">{{nickName}}</span></div>
          <div class="fullname">{{fullName}}</div>
      </div> -->

        <!-- <img src="../../assets/images/MC.png" alt="notification"> -->
      </li>
    </ul>
  </nav>
</header>

<div *ngIf="notificationToggle" class="overlay" id="overlay">

    <!-- The content of the pop-up -->

    <div class="popup-content">

     <!-- <button style="position: absolute;  right:565px" (click)="closeNotifications()">Close</button> -->

        <ul style="list-style-type: none;">
                 <li style="font-weight: bold;">New/Updated content in your Saved Search <a href="#" style="color:teal; font-weight: bold; margin-left: 160px; text-decoration: none;">Clear All</a></li> <br>
                 <li style="line-height: 2;" *ngFor="let notification of notifications">
                  <span  style="color:teal; font-weight: bold; line-height: 1px;">{{notification.PATTERN_NAME}}</span> 
                 <span (click)="expandNotificationRecord(notification)" class="material-icons outlined" style="position: relative;top: 4px;font-size:15px;cursor:pointer;color: #373737">{{notification.collapse}}</span> <span (click)="removeSavedSearchFromNotifications(notification.ID)"  style=" margin-left:10px; font-size: 0.8rem;cursor: pointer;color:teal;">Clear</span>
                 <div *ngIf="notification.collapse == 'expand_less'">
                    <hr style="color:teal">
                         <ul style="list-style-type: none">
                               <li *ngFor = "let update of updates">
                                <span (click)="showNotificationRelatedResults(update.ID)" [style.color]="update.VISITED == 1 ? 'grey':'teal'" style=" font-weight: bold; line-height: 1px; text-decoration: underline; cursor: pointer;">{{update.UPDATED_DOCS}}</span> <span (click)="removeNotification(update.ID)"  style=" margin-left:10px; font-size: 1.0rem;cursor: pointer;color:teal;">X</span>
                               </li>
                               <span *ngIf="loader">Loading...</span>
                         </ul>
                         
                 </div>
                
                 </li>
                 <li *ngIf="loadMore" (click)="loadMoreNotifications()" style="color:teal; cursor: pointer;">Load More</li>
        </ul>
      <!-- If the content exceeds the container height, a scroll bar will appear -->
    </div>
  </div>
